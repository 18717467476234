import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { BodyTextBlock } from '@/components/BodyTextBlock'
import { DatoGatsbyImage } from '@/components/DatoGatsbyImage'
import { Deco } from '@/components/Deco'
import { colors } from '@/theme/variables'

import { bodyGrid, bodyHeading } from '../../styles'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.NoticeBarFragment | null
  layout?: 'DEFAULT' | 'BIG_HEADING'
}

export const NoticeBar = ({
  data,
  layout = 'DEFAULT',
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      ${bodyGrid}
      padding: var(--row-36) var(--margin);
      background: ${colors.gray20};
      border-top: 1rem solid #000;
      border-bottom: 1rem solid #000;
      color: #fff;
    `,
    heading: css`
      ${bodyHeading}
      max-width: fit-content;
      margin: 0.125em 0 0.5em;
      border-color: ${colors.redLight};
      color: #fff;
      ${layout === 'BIG_HEADING' &&
      css`
        display: grid;
        padding: 0;
        border: none;
        text-transform: uppercase;
        justify-items: center;
        text-align: center;
        justify-self: center;
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        margin-top: var(--row-36);
        span {
          margin: 0 0 0.5em;
        }
      `}
    `,
    graphic: css`
      grid-row-end: span 2;
      margin-bottom: var(--row-36);
      align-self: flex-start;
      ${layout === 'BIG_HEADING' &&
      css`
        grid-row-end: auto;
      `}
    `,
    blocks: css`
      align-self: center;
      --button-hover-bg-color: #fff;
      --button-hover-color: ${colors.red};
      --link-color: ${colors.redLight};
      --link-color-hover: #ffffffcc;
      a {
        color: ${colors.redLight};
        font-weight: 500;
        text-decoration-thickness: 1px;
        text-underline-offset: 0.15em;
        @media (hover: hover) {
          &:hover {
            color: #ffffffcc;
          }
        }
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      {data?.graphic && (
        <DatoGatsbyImage
          css={styles.graphic}
          image={data?.graphic.gatsbyImageData}
          alt={data?.graphic?.alt || ''}
        />
      )}
      <h2 css={styles.heading}>
        <span>{data?.heading}</span>
        {layout === 'BIG_HEADING' && <Deco fill={colors.redLight} />}
      </h2>
      <div css={styles.blocks}>
        <BodyTextBlock data={data?.body} />
      </div>
    </section>
  )
}

export const NoticeBarFragment = graphql`
  fragment NoticeBar on DatoCmsNoticeBar {
    id: originalId
    __typename
    heading
    body {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
    }
    graphic {
      gatsbyImageData(width: 720, sizes: "33vw")
      alt
    }
  }
`
